<template>
  <div class="fixed right-10 top-10 flex flex-col gap-4" style="z-index: 999">
    <transition
      enter-active-class="duration-100 animate-bounce-right"
      leave-active-class="transition-transform translate-x-full"
    >
      <div
        v-if="toast != null"
        class="relative z-50 ml-auto flex h-full min-h-[80px] w-max max-w-md flex-row gap-x-3.5 rounded-lg border-2 border-slate-200 bg-white px-3 py-3.5 shadow-lg shadow-slate-600/30"
        :class="{
          'max-h-[42rem]': toast.buttons && toast.buttons.length > 0,
          'max-h-[32rem]': !toast.buttons || toast.buttons.length == 0,
        }"
      >
        <button
          v-if="!toast.hideClose"
          @click="closeToast"
          class="group absolute top-2 right-2 flex h-5 w-5 items-center justify-center rounded-full border bg-slate-200 transition-colors hover:bg-slate-300"
        >
          <UiIconX
            class="h-2.5 w-2.5 text-slate-400 group-hover:text-slate-500"
          />
        </button>

        <div
          :class="skins[toast.type]"
          class="h-10/12 min-w-[4px] rounded-lg"
        />

        <div
          v-if="['error', 'warn', 'success'].includes(toast.type)"
          class="my-auto"
        >
          <UiIconError v-if="toast.type === 'error'" class="h-8 w-8" />

          <UiIconWarn v-else-if="toast.type === 'warn'" class="h-8 w-8" />

          <UiIconSuccess v-else-if="toast.type === 'success'" class="h-8 w-8" />
        </div>

        <div class="mr-7 flex flex-col justify-center space-y-3">
          <p
            class="text-sm font-medium text-slate-500"
            v-html="toast.message"
          />

          <div
            v-if="toast.buttons && toast.buttons.length > 0"
            class="flex items-center space-x-2"
          >
            <button
              type="button"
              class="h-7 rounded border bg-slate-100 px-4 text-xs font-bold text-slate-500 hover:bg-slate-200"
              v-for="(button, i) in toast.buttons"
              :key="i"
              @click="
                button.onClick && button.onClick(toast, close);
                close();
              "
            >
              {{ button.text }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useToast } from "@/store/toast";

const { toast, close } = useToast();

const closeToast = async () => {
  if (toast.value.onClose != null) {
    await toast.value.onClose();
  }
  close();
};

const skins = {
  success: "bg-cad-success-500",
  error: "bg-cad-danger-500",
  warn: "bg-cad-warning-500",
  info: "bg-transparent",
};
</script>
