<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM15.7 14.3C16.1 14.7 16.1 15.3 15.7 15.7C15.3 16.1 14.7 16.1 14.3 15.7L12 13.4L9.7 15.7C9.3 16.1 8.7 16.1 8.3 15.7C7.9 15.3 7.9 14.7 8.3 14.3L10.6 12L8.3 9.7C7.9 9.3 7.9 8.7 8.3 8.3C8.7 7.9 9.3 7.9 9.7 8.3L12 10.6L14.3 8.3C14.7 7.9 15.3 7.9 15.7 8.3C16.1 8.7 16.1 9.3 15.7 9.7L13.4 12L15.7 14.3Z"
      fill="#E35264"
    />
  </svg>
</template>
